<template>
  <!-- .theme-choice .hc -->
  <div class="hc">
    <div class="layout quanpin" @click="linkTo('into')">
      <i class="icon iconfont icon-quanping"></i>
      Fullscreen
    </div>
    <!-- <div class="layout layinto " @click="linkTo('out')">
      <i class="icon iconfont icon-tuichuquanping"></i>
      退出全屏
    </div> -->
    <div
      class="hc-item"
      v-for="(item, index) in themeData"
      :key="'themeData-' + index"
      :style="{color: item.tabColor}"
      :class="{empty: item.color === '#fff', active: curIndex === index}"
      @click="choice(item, index)"
    ><img src="@/assets/img/hall/disable.png" v-if="item.color === '#fff' "></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  // 皮肤选择
  name: "theme-choice",
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      curIndex: 0,
      curData: {
        color: "#f2faff",
        borderColor: "#000",
        boxColor:'#addfff',
        tabColor:"#3498db", // tab色
      },
      themeData: [
        {
          color: "#f2faff",  // 背景色
          borderColor: "#000", // 边框色
          boxColor:'#addfff' ,// 展位图色
          tabColor:"#3498db", // tab色
        },
        {
          color: "#fff7f2",
          borderColor: "#000",
          boxColor:'#f4bf9b',
          tabColor:"#fe781d", // tab色
        },
        {
          color: "#fff2f3",
          borderColor: "#000",
          boxColor:'#eea4aa',
          tabColor:"#e60012", // tab色
        },
        {
          color: "#f1fff8",
          borderColor: "#000",
          boxColor:'#96dbb6',
          tabColor:"#32b16c", // tab色
        },
        {
          color: "#fff2fd",
          borderColor: "#000",
          boxColor:'#f2c5ec',
          tabColor:"#cc5ebb", // tab色
        },
        {
          color: "#fff",
          borderColor: "#000",
          boxColor:'#f2f2f2',
          tabColor:"#fff", // tab色
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
  },
  created(){
    this.initColor();
  },
  methods: {
    choice(item, idx) {
      this.curData = item;
      this.curIndex = idx; 
      localStorage.setItem('choiceColor',JSON.stringify(item));
      this.$emit('choice', item);
    },
    initColor(){
      let All = localStorage.getItem('choiceColor') ? JSON.parse(localStorage.getItem('choiceColor')) : null;
      if(All){
        this.themeData.map((c,index)=>{
          if(c.tabColor == All.tabColor){
              this.curIndex = index; 
          }
        })
      }
    },
    linkTo(type){
      switch(type){
        case 'into' :
          this.$router.replace(`/home/tour-big/${this.exhibitionId}`)
          break;
      }
    }
  }
};
</script>
 
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.hc {
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  right: 0px;
  .layout{
    width: 140px;
    height: 46px;
    background-color: rgba(0,0,0,.4);
    border-radius: 23px;
    border: solid 2px #1890ff;
    @include border_color(#1890ff);
    position: absolute;
    right: 20px;
    top:-70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    i{
      margin-right: 10px;
    }
    &:hover{
      @include background_color(#1890ff);
    }
  }
  .layinto{
    @include background_color(#1890ff);
  }
  &-item {
    margin-right: 20px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: currentColor;
    background: currentColor;
    cursor: pointer;
    &.active {
      border-color: #fff;
    }
    &.empty {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>