<template>
    <div class="find-A">
        <div class="fA-title">
            <div class="swiper-prev icon iconfont icon-arrfill_d-copy-copy" :class="{'prevFirst':isFirst}" slot="button-prev" @click="swiperPrev"></div>
            <swiper :options="swiperOption" class="fA-swiper" ref="swiper">
                <swiperSlide 
                v-for="(item, index) in AllDate" 
                :key="'card' + index" 
                >
                    <span :class="{'active':index == tabindex}" @click="selectTab(item,index)">Day{{index+1}}</span>
                </swiperSlide> 
            </swiper>
            <div class="swiper-next icon iconfont icon-arrfill_d-copy-copy" :class="{'nextLast':isLast}" slot="button-next" @click="swiperNext"></div>
        </div>
        <div class="fA-list" :style="{ height: maxHeight+'px'}">
            <Scroll :on-reach-bottom="loadMore" :height="maxHeight" :loading-text="loadingText" :distance-to-edge="distance" v-if="rankList.length">
                <div class="fA-list-one" v-for="(item,index) in rankList" :key="index" @click="linkTo(item)">
                    <div class="fA-list-one-l">
                        <img :src="item.Banner[0]"  v-if="item.Banner[0]" v-load="'ex_actList'">
                        <img src="@/assets/img/empty.png"  v-else>
                    </div>
                    <div class="fA-list-one-r">
                        <h2 class="fA-list-one-name">{{item.Name}}</h2>
                        <p>{{ item.StartDate | moment('MM/DD HH:mm') }} ~{{ item.EndDate | moment('MM/DD HH:mm') }}</p>
                        <p>{{item.Addr}}</p>
                    </div>
                </div>
            </Scroll>
            <div class="no-rc" v-if="!rankList.length" style="text-align: center;padding:20px;">
                {{searchLoading ? '正在搜索...' :  $t('lang.No_data') + '！'}}
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    name:'find-Act',
    data(){
        return{
            StartDate:null,
            EndDate:null,
            AllDate:null,
            tabindex:0,
            rankList:[],
            PageSize:20,
            PageIndex:1,
            Total:0,
            loadingText:'正在加载，请稍后',
            selectDate:null,
            scrollMaxH:null,
            searchLoading:true,
            swiperOption: {
                slidesPerView: 3,
                autoplay: false,
                // spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev'
                },
                onSlideChangeEnd: swiper => {
                    if(swiper.isEnd){ // 结束
                        this.isLast = true;
                    }else{
                        this.isLast = false;
                    }
                    if(swiper.isBeginning){ // 开始
                        this.isFirst = true;
                    }else{
                        this.isFirst = false;
                    }
                }
            },
            isFirst:true,
            isLast:false,
            distance:-2, // 无线滚动
        }
    },
    components: {
        swiper,
        swiperSlide
    },
    computed: {
        maxHeight(){return window.innerHeight-298},
        exhibitionId(){ return this.$route.query.exhibitionId},
        exInfo(){ return JSON.parse(localStorage.getItem('ExBaseMsg')).info},
        ...mapGetters({
            login: 'hall/getLogin'   // 是否登录
        }),
        
    },
    mounted(){
       this.StartDate = this.exInfo.StartDate.split('T')[0];
       this.EndDate = this.exInfo.EndDate.split('T')[0];
       this.AllDate = this.getAllDate(this.StartDate,this.EndDate);
       this.selectTab(this.AllDate[0],0);
    },
    methods:{
        swiperPrev(){
            const container = this.$refs.swiper;
            container.swiper.slidePrev();
            this.swiperEvent(container);
        },
        swiperNext(){
            const container = this.$refs.swiper;
            container.swiper.slideNext();
            this.swiperEvent(container);
        },
        swiperEvent(container){
            if(container.swiper.isEnd){ // 结束
                this.isLast = true;
            }else{
                this.isLast = false;
            }
            if(container.swiper.isBeginning){ // 开始
                this.isFirst = true;
            }else{
                this.isFirst = false;
            }
        },
        format(time){
            let ymd = ''
            let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1))
            let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate())
            ymd += time.getFullYear() + '-' // 获取年份。
            ymd += mouth + '-' // 获取月份。
            ymd += day // 获取日。
            return ymd // 返回日期。
        },
        getAllDate(start, end){
            let dateArr = []
            let startArr = start.split('-')
            let endArr = end.split('-')
            let db = new Date()
            db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2])
            let de = new Date()
            de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2])
            let unixDb = db.getTime()
            let unixDe = de.getTime()
            let stamp
            const oneDay = 24 * 60 * 60 * 1000;
            for (stamp = unixDb; stamp <= unixDe;) {
            dateArr.push(this.format(new Date(parseInt(stamp))))
            stamp = stamp + oneDay
            }
            return dateArr
        },
        // 选择日期
        selectTab(item,index){
            this.tabindex = index;
            this.selectDate = item;
            this.PageSize= 20;
            this.PageIndex = 1;
            this.rankList = [];
            this.loadingText = '正在加载，请稍后';
            const reqBody = {
                By: this.exhibitionId,
                PageSize: this.PageSize,
                PageIndex: this.PageIndex,
                dtDate:this.selectDate,
            };
            // console.log(reqBody);
            this.searchLoading = true;
            this.$http.post('/api/activity/ranking/dateForpage?order=Comprehensive',reqBody,{headers: {"api-version": 2}})
                      .then(res=>{
                          if(res.data.StatusCode === 200 && res.data.Data){
                            res.data.Data.List.map(c => { 
                                if (c.Banner.search(/^\[/) !== -1) {
                                    c.Banner = JSON.parse(c.Banner);
                                } else {
                                    c.Banner = [c.Banner];
                                }
                            }); 
                            this.searchLoading = false;
                            this.rankList = res.data.Data.List;
                            this.Total = res.data.Data.Total;
                            // if(this.rankList.length && this.rankList.length * 88 < this.maxHeight){
                            //     this.scrollMaxH = this.rankList.length * 88;
                            //     console.log(this.scrollMaxH,'1')
                            // }
                            // if(this.rankList.length && this.rankList.length * 88 > this.maxHeight){
                            //     this.scrollMaxH = this.maxHeight;
                            //     console.log(this.scrollMaxH,'2')
                            // }
                            // if(!this.rankList.length){
                            //     this.scrollMaxH = 78;
                            //     console.log(this.scrollMaxH,'3')
                            // }
                          }
                      })
                      
        },
        loadMore(){
            var that = this;
            this.PageIndex += 1;
            if(this.Total > this.rankList.length){
                return new Promise(resolve => {
                    const reqBody = {
                        By: this.exhibitionId,
                        PageSize: this.PageSize,
                        PageIndex: this.PageIndex,
                        dtDate:this.selectDate,
                    };
                    this.$http.post('/api/activity/ranking/dateForpage?order=Comprehensive',reqBody,{headers: {"api-version": 2}})
                            .then(doc => {
                                    let state = doc.data;
                                    if(doc.data.StatusCode === 200 && state.Data){
                                        state.Data.List.map(c => {
                                            if (c.Banner.search(/^\[/) !== -1) {
                                                c.Banner = JSON.parse(c.Banner);
                                            } else {
                                                c.Banner = [c.Banner];
                                            }
                                        });
                                        let copyData = [];
                                        if (that.rankList && that.rankList.length > 0) {
                                            copyData = that.rankList;
                                        }
                                        // console.log(that.rankList)
                                        that.rankList = state.Data.List;
                                        that.rankList = [...copyData,...that.rankList];
                                        resolve();
                                    }
                            });
                });
            }else{
                this.loadingText = '没有更多'
            }
        },
        linkTo(item){
            if (!this.login) {
                this.$Message.warning(`您还未登录，请先登录再进行操作`);
                this.$emit('isLogin')
                return;
            }
            let routeData = this.$router.resolve({
                    name: "activity-details",
                    query:{ItemId:item.Id},
                    params:{id:this.exhibitionId}
                });
            window.open(routeData.href, '_blank')
        },
        
    }
    
}
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.find-A{
    position: fixed;
    z-index: 700;
    top: 166px;
    left: 14px;
    width: 396px; 
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    background: #fff;
}
.fA-title{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #f5f5f5;
    span{
        font-size: 14px;
        color: #666;
        cursor: pointer;
    }
    span.active{
        @include font_color(#1890ff);
    }
}
.fA-list{
    // max-height: 552px;
    overflow-y: auto;
    padding-left: 19px;
    &-one{
        display: flex;
        align-items: center;
        padding:10px 0 ;
        border-bottom: 1px solid #f5f5f5;
        &-l{
            width: 100px;
            height: 66px;
            border-radius: 2px;
            margin-right: 10px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 2px;
            }
        }
        &-r{
            width: calc(100% - 110px);
            cursor: pointer;
            h2{
                font-size: 14px;
                line-height: 25px;
                font-weight: normal;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p{
                font-size: 12px;
                line-height: 20px;
                color: #808080;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
.fA-list-one:first-child{
    padding-top: 0;
}
.swiper-slide{
    text-align: center;
    width: 125.333px;
}
.swiper-prev,.swiper-next{
    position: relative;
    width: 27px;
    height: 44px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 20px;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}
.swiper-prev{
    left:10px;
    right: auto;
    transform: rotate(180deg);
}
.swiper-next{
    left:auto;
    right: 10px;
}
.fA-swiper{
    width:80%;
}
.prevFirst,.nextLast{
    color:#ccc;
}
</style>